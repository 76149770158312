.about {
    /* height: 100vh; */
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, #ff0062 100%);
}

.about-m {
    /* height: 100vh; */
    background: #ff0062;
    background: linear-gradient(90deg, rgb(241, 70, 122) 0%, #fa97bd 0%, #ff0062 100%);
}