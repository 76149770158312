.landing-particles {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(80deg, #ffffff 50%, #ff0062 50%);
    /*old color #ff0062 */
}

.landing-particles-m {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    /*old color #ff0062 */
}

.landing-m {
    height: 100vh;
}

.container-main {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.container-left {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.container-left-box {
    margin: 3rem;
    width: 100%;
}

.container-left-content {
    padding-top: 5%;
    padding-left: 10%;
    padding-bottom: 20%;
    /* border-style: none none none solid;
    border-radius: 10%;
    border-color: rgb(41, 41, 41); */
}

.container-right {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.container-right-box {
    margin: 3rem;
    width: 100%;
}

.container-right-content {
    color: white;
    /* background-color: #3700ff; */
    /* border-style: none solid solid none; */
    /* border-radius: 20%; */
    /* border-color: rgba(41, 41, 41, 0.425); */
    /* width: 80%; */
}

.landing-avatar {
    margin-right: 25%;
}

.landing-title {
    font-size: 1.3em;
}

.landing-quote {
    font-weight: bold;
}

#tsparticles {
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
}

a {
    z-index: 1;
}